import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Avatar, Box, ButtonBase, Button, Typography, IconButton } from '@material-ui/core';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import { drawerWidth } from '../../../store/constant';
import { Link as RouterLink } from 'react-router-dom';
import CarAnimation from '../../../Components/CarAnimation';
import configData from '../../../config';
import SosIcon from '@mui/icons-material/Sos';
import ChatIcon from '@mui/icons-material/Chat';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import OnOffSwitch from '../../../Components/OnOffSwitch';
import '../../../assets/scss/style.css';

// assets
import { IconMenu2 } from '@tabler/icons';
import { useSelector } from 'react-redux';
// style constant
const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1
    },
    headerAvatar: {
        ...theme.typography.commonAvatar,
        ...theme.typography.mediumAvatar,
        transition: 'all .2s ease-in-out',
        // background: theme.palette.secondary.light,
        // color: theme.palette.secondary.dark,
        // '&:hover': {
        //     background: theme.palette.secondary.dark,
        //     color: theme.palette.secondary.light
        // }
    },
    iconButton: {
        backgroundColor: '#f44949',
        color: 'white',
        '&:hover': {
            background: theme.palette.secondary.dark,
            // color: theme.palette.secondary.light
        },
      },
    boxContainer: {
        width: '200px',
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            width: 'auto'
        }
    }, 
    disp : {
        bgcolor:'primary.light'
    },
    root: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    }
}));

//-----------------------|| MAIN NAVBAR / HEADER ||-----------------------//

const Header = ({ handleLeftDrawerToggle, chatOpen, setChatOpen }) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const account = useSelector((state) => state.account);
    const { name, role, id } = account;
    function handleSosClick() {
        setOpen(true);
    }
    function handleClose(){
        setOpen(false);
    }

    function handleSOS(){
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const reasonValue = document.getElementById('sos_message') && document.getElementById('sos_message').value;
                const date = new Date();
                const sendingData = {
                    source_type: role,
                    trigger_time: date,
                    sos_lan: position.coords.longitude,
                    sos_lat:  position.coords.latitude,
                    message: reasonValue,
                    status: 'Opened',
                    source_id: id
                }
                console.log(sendingData);
                axios.post(configData.API_SERVER + 'sos/', sendingData)
                .then((response) => {
                    console.log('SOS sent successfully')
                })
                .catch((error) => {
                    console.log('Failed to send SOS')
                })

            },
            (error) => {
                console.error('Error getting current location:', error.message);
            },
            {
                enableHighAccuracy: true, // Request high accuracy
                timeout: 10000, // Set a timeout
                maximumAge: 0 // Do not use a cached position
            }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
        handleClose();
    }

    function handleChatClick(){
        setChatOpen(!chatOpen);
    }
    
    return (
        <React.Fragment>
            {/* logo & toggler button */}
            <div className={classes.boxContainer}>
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '1px',  overflow: 'hidden' }}>
                    <Avatar variant="rounded" className={classes.headerAvatar} onClick={handleLeftDrawerToggle} color="inherit">
                    <IconButton className={classes.iconButton} >
                    <IconMenu2 />
                </IconButton>
                        
                    </Avatar>
                </ButtonBase>
            </div>
            <Typography variant="h1" component="div" sx={{ flexGrow: 1, align:"center", p:2, minWidth: 50, fontFamily:'serif', color:'navy' }}>
                {configData.TITLE_TXT}
                <CarAnimation />
            </Typography>
            {role == 'Driver' ?
            (<div className={classes.root}>
                <OnOffSwitch />
            </div>) : null}
            <div className='sos'>
                <IconButton className={classes.iconButton} onClick={handleSosClick}>
                    <SosIcon />
                </IconButton>
            </div>
            <div className={`${classes.root} Chat`}>
                {role == 'Admin' ? 
                    <RouterLink to={'/chat_board'}>
                        <IconButton className={classes.iconButton}>
                            <ChatIcon />
                        </IconButton>
                    </RouterLink> :
                    <IconButton className={classes.iconButton} onClick={handleChatClick}>
                        <ChatIcon />
                    </IconButton>
                }
            </div>
            <div >
                <NotificationSection/>
            </div>
            <div className={classes.root}>
                <label className='form-label'>{name}</label>
                <RouterLink to={'/logout'}>
                    <Button variant="contained" >Logout</Button>
                </RouterLink>
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    SOS Button sends Emergency Message to Management. Are you sure you want to Send.<br/>
                    <TextField autoFocus margin="dense" id="sos_message" label="Reason for the SOS"
                    type="text" variant="standard" fullWidth  />
                </DialogContent>
                <DialogActions>
                  <Grid item container spacing={2} sx={{display: 'flex', justifyContent: 'right'}}>
                    <Grid item>
                      <Button onClick={handleClose} color="primary">No</Button>
                    </Grid>
                    <Grid item>
                      <Button onClick={handleSOS} color="primary">Yes</Button>
                    </Grid>
                  </Grid>
                </DialogActions>
              </Dialog>
        </React.Fragment>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
