import React, {useState} from 'react';
import '../../assets/scss/style.css';
import { Link } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChatIcon from '@mui/icons-material/Chat';
import { ButtonBase, IconButton } from '@material-ui/core';
import { useSelector } from 'react-redux';
import config from '../../config';
import { makeStyles } from '@material-ui/styles';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import ChatWindow from '../../Components/ChatWindow';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
}))
const Footer = () => {
    const account = useSelector((state) => state.account);
    const { role } = account;
    const classes = useStyles();
    const [chatOpen, setChatOpen] = useState(false);

    function handleChatClick(){
        setChatOpen(!chatOpen);
    }

    return (
        <footer className={`${classes.root} no-print`}>
            <div className='footer'>
                <div className="row">
                    <div className='footer-icon'>
                        <ButtonBase disableRipple component={Link} to={config.defaultPath[role]}>
                            <HomeIcon />
                        </ButtonBase>
                        <h4 className="footer-text">Home</h4>
                    </div>
                </div>
                <div className="row">
                    <div className='footer-icon'>
                    {role == 'Admin' ? 
                        <ButtonBase component={RouterLink} to="/chat_board">
                            <ChatIcon />
                        </ButtonBase> : 
                        <ButtonBase onClick={handleChatClick}>
                            <ChatIcon />
                        </ButtonBase>
                    }
                        <h4 className="footer-text">Query</h4>
                    </div>
                </div>
                {role == 'Driver' ?
                (<div className="row">
                    <div className='footer-icon'>
                        <RouterLink to={'/trip/triplist/?trip_status=Assigned'} style={{ color: 'inherit', textDecoration: 'none' }}>
                            <TimeToLeaveIcon />
                        </RouterLink>
                        <h4 className="footer-text">My Duties</h4>
                    </div>
                </div>) : null}
                <div className="row">
                    <div className='footer-icon'>
                        <RouterLink to={'/employee_profile_setting'} style={{ color: 'inherit', textDecoration: 'none' }}>
                            <PersonIcon />
                        </RouterLink>
                        <h4 className="footer-text">Profile</h4>
                    </div>
                </div>
            </div>
            {chatOpen && <ChatWindow setChatOpen={setChatOpen} TripId={null}/>}
        </footer>
    );
}

export default Footer;