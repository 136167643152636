import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import configData from '../config';
import { IconButton, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MinimizeIcon from '@mui/icons-material/Minimize';
import colors from '../assets/scss/_themes-vars.module.scss';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';

const ChatWindow = ({ setChatOpen, TripId }) => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const [ws, setWs] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const account = useSelector((state) => state.account);
    const { user, token, id } = account;
    const messagesEndRef = useRef(null);

    const loadMessages = async (room_name, page) => {
        try {
            const response = await axios.get(`${configData.API_SERVER}chat/messages/?room_name=${room_name}&page=${page}`);
            const newMessages = response.data.results;
            setMessages(prevMessages => page === 1 ? newMessages : [...prevMessages, ...newMessages]);
            setHasMore(response.data.next !== null);
        } catch (error) {
            if (error.response && error.response.status === 404) {
                // Handle 404 error gracefully
                console.log("Chat room not found, continue with WebSocket connection.");
            } else {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        if(TripId){
            loadMessages(`${TripId}_${user}`, page);
        } else {
            loadMessages(`admin_${user}`, page);
        }

        const chatSocket = new WebSocket(`${configData.WS_SERVER}ws/chat/?token=${token}${TripId ? `&trip_id=${TripId}` : ''}`);

        chatSocket.onopen = () => {
            console.log('WebSocket connection established');
            setWs(chatSocket);
        };

        chatSocket.onerror = (error) => {
            console.log('WebSocket connection failed with error:', error);
        };

        chatSocket.onclose = () => {
            console.log('WebSocket connection closed');
            setWs(null);
        };

        chatSocket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            console.log('WebSocket message received:', data);
            setMessages(prevMessages => [{ content: data.message, user: { id: data.user_id, username: data.username, room_name: data.room_name }, timestamp: data.timestamp }, ...prevMessages]);
        };

        return () => {
            chatSocket.close();
        };
    }, []);

    useEffect(() => {
        if (page === 1) {
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleSendMessage = () => {
        if (input.trim()) {
            const newMessage = {
                content: input,
                user: { id: id, username: user },
                timestamp: new Date().toISOString()
            };

            if(TripId && messages.length == 0){
                const sendingData = {
                    'trip_id': TripId,
                    'username': user,
                }
                axios.post(configData.API_SERVER + 'notifications/trip_admin_support_notification/', sendingData)
                .then((response) => {
                    console.log(response.data);
                }).catch((error) => {
                    console.log(error.response);
                })
            }

            setMessages([newMessage, ...messages]);

            ws.send(JSON.stringify({
                'message': input,
                'recipient': '',
                'timestamp': new Date().toISOString()
            }));

            setInput('');
        }
    };

    const handleChatClose = () => {
        setChatOpen(false);
        if (ws) {
            ws.close();
        }
        setWs(null);
    };

    const handleScroll = (e) => {
        if (e.target.scrollTop === 0 && hasMore) {
            const nextPage = page + 1;
            if(TripId){
                loadMessages(`${TripId}_${user}`, nextPage);
            } else {
                loadMessages(`admin_${user}`, nextPage);
            }
            setPage(nextPage);
        }
    };

    const formatAMPM = (date) => {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; 
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    };

    const toTimeValue = (strdate) => {
        const ydate = new Date(strdate);
        return formatAMPM(ydate);
    };

    const getDateLabel = (strdate) => {
        const ydate = new Date(strdate);
        const now = new Date();
        const yesterday = new Date(now);
        yesterday.setDate(yesterday.getDate() - 1);
      
        if (ydate.toDateString() === now.toDateString()) {
          return 'TODAY';
        } else if (ydate.toDateString() === yesterday.toDateString()) {
          return 'YESTERDAY';
        } else {
          const day = ydate.getDate().toString().padStart(2, '0');
          const month = (ydate.getMonth() + 1).toString().padStart(2, '0');
          const year = ydate.getFullYear();
          return `${day}-${month}-${year}`;
        }
    };

    const groupMessagesByDate = (messages) => {
        return messages.reduce((acc, message) => {
            const dateLabel = getDateLabel(message.timestamp);
            if (!acc[dateLabel]) {
                acc[dateLabel] = [];
            }
            acc[dateLabel].push(message);
            return acc;
        }, {});
    };

    const groupedMessages = groupMessagesByDate(messages.slice(0).reverse());

    return (
        <div className={`chat-container ${isOpen ? 'open' : ''}`}>
            <div className="chat-header" style={{ backgroundColor: colors.primaryMain }}>
                <Typography variant="h5">Chat with us</Typography>
                <div className="chat-controls">
                    <IconButton size="small" onClick={() => handleChatClose()}>
                        <CloseIcon style={{ color: 'black' }} />
                    </IconButton>
                </div>
            </div>
            {isOpen && (
                <Box className="chat-body" >
                    <div className="messages" onScroll={handleScroll}>
                        {Object.keys(groupedMessages).map((date, index) => (
                            <React.Fragment key={index}>
                                <Box display="flex" justifyContent="center" my={2}>
                                    <Typography variant="caption" color="textSecondary">
                                        {date}
                                    </Typography>
                                </Box>
                                {groupedMessages[date].map((msg, msgIndex) => (
                                    <Box key={msgIndex} display="flex" flexDirection="column" alignItems={msg.user.id !== id ? 'flex-start' : 'flex-end'} >
                                        <Box
                                            bgcolor={msg.user.id !== id ? '#e0e0e0' : '#ffb108'}
                                            color={msg.user.id !== id ? 'black' : 'white'}
                                            p={1} borderRadius={1} maxWidth="70%" minWidth="20%" spacing={1}
                                            style={{ wordWrap: 'break-word', position: 'relative' }}
                                        >
                                            <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
                                                {msg.user.username}
                                            </Typography>
                                            <Typography variant="body1" style={{ fontSize: '1rem', marginBottom: '10px' }}>
                                                {msg.content}
                                            </Typography>
                                            <Typography variant="body1" style={{ fontSize: '0.7rem', position: 'absolute', bottom: 0, right: 0, padding: '2px' }} >
                                                {toTimeValue(msg.timestamp)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </React.Fragment>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                    <div className="chat-input">
                        <input
                            type="text"
                            value={input}
                            onChange={handleInputChange}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSendMessage();
                                }
                            }}
                            placeholder="Type your message..."
                        />
                        <IconButton onClick={handleSendMessage} color="secondary">
                            <SendIcon />
                        </IconButton>
                    </div>
                </Box>
            )}
        </div>
    );
};

export default ChatWindow;

