import React, { useState, useEffect } from 'react';
import axios from 'axios';
import configData from '../config';
import { errorHandling } from '../utils/general';
import DriverLocationComponent from './trips/DriverWebSocket';
import { useSelector } from 'react-redux';

const OnOffSwitch = () => {
  const [isOn, setIsOn] = useState(false);
  const [error, setError] = useState(null);
  const [driverId, setDriverId] = useState(null);
  const [ws, setWs] = useState(null);
  const [driverName, setDriverName] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const account = useSelector((state) => state.account);
  const { user, id, token } = account;

  useEffect(() => {
    axios.get(configData.API_SERVER + 'driver/?driver_contact_number=' + user)
      .then((response) => {
        const data = response.data[0];
        axios.get(configData.API_SERVER + 'vehicle/?driver_id=' + data.driver_id)
        .then((response) => {
          const value = response.data[0];
          setDriverId(data.driver_id);
          setDriverName(data.driver_name);
          setIsOn(data.status === 'Active');
          setVehicle(value.vehicle_number)
        })
      })
      .catch((error) => {
        errorHandling(error.response, setError);
      });
  }, []);

  useEffect(() => {
    if (isOn) {
      const socket = new WebSocket(configData.WS_SERVER + `ws/driver_updates/?token=${token}`);
      console.log('WebSocket URL:', socket);

      socket.onopen = () => {
        console.log('WebSocket connection established');
        setWs(socket);

        // Schedule interval to fetch and send driver location every 5 seconds
        const intervalId = setInterval(() => {
          fetchAndSendDriverLocation(socket);
        }, 5000);

        // Cleanup function to clear interval and close WebSocket
        socket.onclose = () => {
          console.log('WebSocket connection closed');
          clearInterval(intervalId); // Clear the interval
          setWs(null); // Reset WebSocket state
        };
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log('WebSocket message received:', data);
        // Handle received data here (e.g., update markers)
      };

      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
        setWs(null);
      };
    } else {
      // Disconnect WebSocket if driver is inactive
      if (ws) {
        ws.close();
        console.log('WebSocket connection closed');
      }
      setWs(null);
    }
  }, [isOn]);

  const fetchAndSendDriverLocation = (socket) => {
    // Fetch driver's GPS location and send it to backend
    if (navigator.geolocation && driverId) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const data = {
          latitude: latitude,
          longitude: longitude,
          driver_id: driverId,
          driver_name: driverName,
          vehicle_number: vehicle
        };
        if (socket && socket.readyState === WebSocket.OPEN) {
          socket.send(JSON.stringify(data));
          console.log('Driver location sent:', data);
        }
      },
      (error) => {
          console.error('Error getting current location:', error.message);
          // Fallback mechanism or notify the user
      },
      {
          enableHighAccuracy: true, // Request high accuracy
          timeout: 10000, // Set a timeout
          maximumAge: 0 // Do not use a cached position
      }
      );
    }
  };

  const toggleSwitch = () => {
    const driverStatus = {
      status: isOn ? 'Inactive' : 'Active',
      user: JSON.stringify({ id: id })
    };

    axios.patch(configData.API_SERVER + 'driver/' + driverId + '/', driverStatus)
      .then((response) => {
        setIsOn(!isOn);
        console.log('Status Updated');
      })
      .catch((error) => {
        errorHandling(error.response, setError);
      });
  };

  return (
    <div>
      <p className='switchP' style={{ color: isOn ? 'green' : 'red' }}>
        {isOn ? 'Active' : 'Inactive'}
      </p>
      <label className="switch">
        <input type="checkbox" checked={isOn} onChange={toggleSwitch} />
        <span className="slider round"></span>
      </label>
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default OnOffSwitch;
