
//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'myapi.view_dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: 'IconDashboard',
            breadcrumbs: false
        },
        {
            id: 'admin.view_logentry',
            title: 'Notifications',
            type: 'item',
            url: '/notification_list',
            icon: 'IconNotification',
            breadcrumbs: false
        }
    ]
};
