
//-----------------------|| securities MENU ITEMS ||-----------------------//
                
export const trips = {
    id: 'trips',
    title: 'Trips',
    type: 'group',
    children: [
        {
            id: 'myapi.view_travel_request',
            title: 'Travel Request ',
            type: 'item',
            url: '/travel_request_list',
            icon: 'IconCompass',
            breadcrumbs: false
        },
        {
            id: 'myapi.add_tbltrip_booking',
            title: 'Booking',
            type: 'item',
            url: '/trip/trip_booking',
            icon: 'IconBookmark',
            breadcrumbs: false
        }, {
            id: 'myapi.view_tbltrip_list',
            title: 'Trip List',
            type: 'item',
            url: '/trip/triplist/',
            icon: 'IconList',
            breadcrumbs: false
        },
        {
            id: 'myapi.view_tbltrip_tripsheet',
            title: 'Trip Sheet',
            type: 'item',
            url: '/trip/triplist?trip_status=Assigned',
            icon: 'IconClipboardList',
            breadcrumbs: false
        },
        {
            id: 'myapi.view_tblinvoice',
            title: 'Invoice',
            type: 'item',
            url: '/invoice_list',
            icon: 'IconFileCheck',
            breadcrumbs: false
        },
        {
            id: 'accounts.view_payment',
            title: 'Payments',
            type: 'item',
            url: '/payment_list',
            icon: 'IconCash',
            breadcrumbs: false
        },
        {
            id: 'accounts.view_creditnote',
            title: 'Credit Notes',
            type: 'item',
            url: '/credit_note_list',
            icon: 'IconCreditCard',
            breadcrumbs: false
        },
        {
            id: 'accounts.view_creditnote',
            title: 'Others',
            type: 'collapse', 
            icon: 'IconCreditCard',
            breadcrumbs: false,
            children: [
                {
                    // id: 'accounts.create_creditnote',
                    title: 'Employee',
                    type: 'item',
                    url: '/org_employee_list',
                    icon: 'IconUser',
                    breadcrumbs: false
                },
                {
                    // id: 'accounts.view_creditnote.list',
                    title: 'Salary List',
                    type: 'item',
                    url: '/salary_list',
                    icon: 'IconCoin',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'accounts.view_creditnote',
            title: 'Vehicle Accounts',
            type: 'collapse', 
            icon: 'IconCreditCard',
            breadcrumbs: false,
            children: [
                {
                    // id: 'accounts.create_creditnote',
                    title: 'Client Hire',
                    type: 'item',
                    url: '/vehicle_hire_list',
                    icon: 'IconUserCheck',
                    breadcrumbs: false
                },
                {
                    // id: 'accounts.create_creditnote',
                    title: 'Payments',
                    type: 'item',
                    url: '/vehicle_payments',
                    icon: 'IconCash',
                    breadcrumbs: false
                },
                {
                    // id: 'accounts.create_creditnote',
                    title: 'Voucher',
                    type: 'item',
                    url: '/voucher_list',
                    icon: 'IconClipboardList',
                    breadcrumbs: false
                },
            ]
        }
    ]
};
