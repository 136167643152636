import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';
import { Redirect } from 'react-router-dom';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/pages/LandingPage')));
const TripList = Loadable(lazy(() => import('../Components/trips/triplist')));
const TripBooking = Loadable(lazy(() => import('../Components/trips/TripBooking')));
const TripSheet = Loadable(lazy(() => import('../Components/trips/TripSheet')));
const DriverTripSheet = Loadable(lazy(() => import('../Components/trips/DriverTripSheet')));
const TripView = Loadable(lazy(() => import('../Components/trips/TripView')));
const TripAllotment = Loadable(lazy(() => import('../Components/trips/TripAllocation')));
//const SearchBar = Loadable(lazy(() => import('../Components/SearchBar/SearchBar')));
const Logout = Loadable(lazy(() => import('../views/pages/authentication/Logout')));
//const Sample = Loadable(lazy(() => import ('../Components/BasicExampleDataGrid')));
const ClientList = Loadable(lazy(() => import ('../Components/clientList')));
const ClientData = Loadable(lazy(() => import ('../Components/ClientData')));
//const ClientEdit = Loadable(lazy(() => import ('../Components/ClientEdit')));
const ClientProjectList = Loadable(lazy(() => import ('../Components/ClientProjectList')));
const ClientProjectData = Loadable(lazy(() => import ('../Components/ClientProjectData')));
const DriverList = Loadable(lazy(() => import ('../Components/driverList')));
const DriverData = Loadable(lazy(() => import ('../Components/DriverData')));
const VehicleList = Loadable(lazy(() => import ('../Components/VehicleList')));
const VehicleData = Loadable(lazy(() => import ('../Components/VehicleData')));
const VehicleAssign = Loadable(lazy(() => import ('../Components/VehicleAssign')));
//const Sample2 = Loadable(lazy(() => import ('../Components/FullFeaturedCrudGrid')));
const ClientCommercialList = Loadable(lazy(() => import ('../Components/ClientCommercialList')));
const ClientCommercialData = Loadable(lazy(() => import ('../Components/clientCommercialData')));
const ClientProjectView = Loadable(lazy(() => import ('../Components/ClientProjectView')));
const VehicleView = Loadable(lazy(() => import ('../Components/VehicleView')));
const ClientView = Loadable(lazy(() => import ('../Components/ClientView')));
const DriverView = Loadable(lazy(() => import ('../Components/DriverView')));
const CommercialView = Loadable(lazy(() => import ('../Components/CommercialView')));
const DynamicList = Loadable(lazy(() => import ('../Components/DynamicList')));
const StylingRowsGrid = Loadable(lazy(() => import ('../ui-component/StylingRowsGrid')));
const UserManagementList = Loadable(lazy(() => import ('../Components/UserManagementList')));
const UserManagementData = Loadable(lazy(() => import ('../Components/UserManagementData')));
const UserManagementView = Loadable(lazy(() => import ('../Components/UserManagementView')));
const NotificationList = Loadable(lazy(() => import ('../Components/NotificationList')));
const UserResetPassword = Loadable(lazy(() => import ('../Components/UserResetPassword')));
const EmployeeList = Loadable(lazy(() => import ('../Components/EmployeeList')));
const EmployeeData = Loadable(lazy(() => import ('../Components/EmployeeData')));
const EmployeeView = Loadable(lazy(() => import ('../Components/EmployeeView')));
const TravelRequestList = Loadable(lazy(() => import ('../Components/TravelRequestList')));
const TravelRequestData = Loadable(lazy(() => import ('../Components/TravelRequestData')));
const TravelRequestView = Loadable(lazy(() => import ('../Components/TravelRequestView')));
const DriverTripView = Loadable(lazy(() => import('../Components/trips/DriverTripView')));
const EmployeeProfileSetting = Loadable(lazy(() => import ('../Components/EmployeeProfileSetting')));
const TravelRequestFeedback = Loadable(lazy(() => import ('../Components/TravelRequestFeedback')));
const TravelFeedbackView = Loadable(lazy(() => import ('../Components/TravelFeedbackView')));
const InvoiceSheet = Loadable(lazy(() => import ('../Components/InvoiceSheet')));
const BankAccData = Loadable(lazy(() => import ('../Components/BankAccData')));
const BankAccList = Loadable(lazy(() => import ('../Components/BankAccList')));
const BankAccView = Loadable(lazy(() => import ('../Components/BankAccView')));
const InvoiceFormData = Loadable(lazy(() => import ('../Components/InvoiceFormData')));
const InvoiceFormList = Loadable(lazy(() => import ('../Components/InvoiceFormList')));
const InvoiceFormView = Loadable(lazy(() => import ('../Components/InvoiceFormView')));
const InvoiceList = Loadable(lazy(() => import ('../Components/InvoiceList')));
const PaymentForm = Loadable(lazy(() => import('../Components/PaymentForm')));
const DigInvoiceData = Loadable(lazy(() => import('../Components/DigInvoiceData')));
const GenerateInvoiceData = Loadable(lazy(() => import('../Components/GenerateInvoiceData')));
const ManualInvoice = Loadable(lazy(() => import('../Components/ManualInvoice')));
const PaymentList = Loadable(lazy(() => import('../Components/PaymentList')));
const PaymentData = Loadable(lazy(() => import('../Components/PaymentData')));
const ChatBoard = Loadable(lazy(() => import('../Components/ChatBoard')));
const CreditNote = Loadable(lazy(() => import('../Components/CreditNote')));
const VenderProfileData = Loadable(lazy(() => import('../Components/VenderProfileData')));
const VenderProfileList = Loadable(lazy(() => import('../Components/VenderProfileList')));
const VenderProfileView = Loadable(lazy(() => import('../Components/VenderProfileView')));
const PassengersInvoice = Loadable(lazy(() => import('../Components/PassengersInvoice')));
const CreditNoteList = Loadable(lazy(() => import('../Components/CreditNoteList')));
const DriverSalary = Loadable(lazy(() => import('../Components/DriverSalary')));
const SalaryList = Loadable(lazy(() => import('../Components/SalaryList')));
const SalaryData = Loadable(lazy(() => import('../Components/Salarydata')));
const SalaryView = Loadable(lazy(() => import('../Components/SalaryView')));
const OrgEmployeeList = Loadable(lazy(() => import('../Components/OrgEmployeeList')));
const OrgEmployeeData = Loadable(lazy(() => import('../Components/OrgEmployeeData')));
const OrgEmployeeView = Loadable(lazy(() => import('../Components/OrgEmployeeView')));
const VehiclePayments = Loadable(lazy(() => import('../Components/VehiclePayments')));
const VehiclePaymentsData = Loadable(lazy(() => import('../Components/VehiclePaymentsData')));
const VehicleHireList = Loadable(lazy(() => import('../Components/VehicleHireList')));
const VehicleHireData = Loadable(lazy(() => import('../Components/VehicleHireData')));
const VoucherList = Loadable(lazy(() => import('../Components/VoucherList')));
const VoucherData = Loadable(lazy(() => import('../Components/VoucherData')));
const VoucherView = Loadable(lazy(() => import('../Components/VoucherView')));
// const EmployeeProfileSetting = Loadable(lazy(() => import('../Components/EmployeeProfileSetting')));
//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/dashboard/default',
                '/trip/triplist',
                '/trip/trip_booking',
				'/trip/trip_allotment',
                '/trip/trip_sheet',
                '/trip/trip_view',
                '/trip/driver_trip_sheet',
                '/style_grid',
                '/client_list',
                '/client_data',
                '/client_edit',
                '/client_view',
                '/client_project_list',
                '/client_project_data',
                '/client_project_view',
                '/driver_list',
                '/driver_data',
                '/driver_view',
                '/vehicle_list',
                '/vehicle_data',
                '/vehicle_view',
                '/vehicle_assign',
                '/client_commercial_list',
                '/client_commercial_data',
                '/commercial_view',
                '/dynamic_list',
                '/logout',
                '/user_management_list',
                '/user_management_data',
                '/user_management_view',
                '/notification_list',
                '/user_reset_password',
                '/employee_list',
                '/employee_data',
                '/employee_view',
                '/travel_request_list',
                '/travel_request_data',
                '/travel_request_view',
                '/trip/driver_trip_view',
                '/employee_profile_setting',
                '/travel_request_feedback',
                '/travel_feedback_view',
                '/invoice_sheet',
                '/bank_acc_data',
                '/bank_acc_list',
                '/bank_acc_view',
                '/payment_form',
                '/invoice_form_data',
                '/invoice_form_list',
                '/invoice_form_view',
                '/invoice_list',
                '/dig_invoice_data',
                '/invoice_generate_data',
                '/manual_invoice',
                '/payment_list',
                '/payment_data',
                '/chat_board',
                '/credit_note',
                '/vender_data',
                '/vender_list',
                '/vender_view',
                '/passenger_invoice',
                '/credit_note_list',
                '/driver_salary',
                '/salary_list',
                '/salary_data',
                '/salary_view',,
                '/org_employee_list',
                '/org_employee_data',
                '/org_employee_view',
                '/vehicle_payments',
                '/vehicle_payments_data',
                '/vehicle_hire_list',
                '/vehicle_hire_data',
                '/voucher_list',
                '/voucher_data',
                '/voucher_view',
            ]}>

            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <AuthGuard>
                        <Route path="/dashboard/default" component={DashboardDefault} />
                        <Route path="/trip/triplist" component={TripList} />
                        <Route path="/trip/trip_booking" component={TripBooking} />
                        <Route path="/trip/trip_allotment" component={TripAllotment} />
                        <Route path="/trip/trip_sheet" component={TripSheet} />
                        <Route path="/trip/trip_view" component={TripView} />
  						<Route path="/logout" component={Logout} />
                        <Route path="/client_list" component={ClientList} />
                        <Route path="/client_data" component={ClientData} />
                        <Route path="/client_project_list" component={ClientProjectList} />
                        <Route path="/client_project_data" component={ClientProjectData} />
                        <Route path="/driver_list" component={DriverList} />
                        <Route path="/driver_data" component={DriverData} />
                        <Route path="/vehicle_list" component={VehicleList} />
                        <Route path="/vehicle_data" component={VehicleData} />
                        <Route path="/client_commercial_list" component={ClientCommercialList} />
                        <Route path="/client_commercial_data" component={ClientCommercialData} />
                        <Route path="/vehicle_assign" component={VehicleAssign} />
                        <Route path="/client_view" component={ClientView} />
                        <Route path="/driver_view" component={DriverView} />
                        <Route path="/commercial_view" component={CommercialView} />
                        <Route path="/vehicle_view" component={VehicleView} />
                        <Route path="/client_project_view" component={ClientProjectView} />
                        <Route path="/dynamic_list" component={DynamicList} />
                        <Route path="/trip/driver_trip_sheet" component={DriverTripSheet} />
                        <Route path="/style_grid" component={StylingRowsGrid} />
                        <Route path="/user_management_list" component={UserManagementList} />
                        <Route path="/user_management_data" component={UserManagementData} />
                        <Route path="/user_management_view" component={UserManagementView} />
                        <Route path="/notification_list" component={NotificationList} />
                        <Route path="/user_reset_password" component={UserResetPassword} />
                        <Route path="/employee_list" component={EmployeeList} />
                        <Route path="/employee_data" component={EmployeeData} />
                        <Route path="/employee_view" component={EmployeeView} />
                        <Route path="/travel_request_list" component={TravelRequestList} /> 
                        <Route path="/travel_request_data" component={TravelRequestData} /> 
                        <Route path="/travel_request_view" component={TravelRequestView} /> 
                        <Route path="/trip/driver_trip_view" component={DriverTripView} />
                        <Route path="/employee_profile_setting" component={EmployeeProfileSetting} />
                        <Route path="/travel_request_feedback" component={TravelRequestFeedback} />
                        <Route path="/travel_feedback_view" component={TravelFeedbackView} />
                        <Route path="/invoice_sheet" component={InvoiceSheet} />
                        <Route path="/bank_acc_data" component={BankAccData} />
                        <Route path="/bank_acc_list" component={BankAccList} />
                        <Route path="/bank_acc_view" component={BankAccView} />
                        <Route path="/invoice_form_data" component={InvoiceFormData} />
                        <Route path="/invoice_form_list" component={InvoiceFormList} />
                        <Route path="/invoice_form_view" component={InvoiceFormView} />
                        <Route path="/invoice_list" component={InvoiceList} />
                        <Route path="/payment_form" component={PaymentForm} />
                        <Route path="/dig_invoice_data" component={DigInvoiceData} />
                        <Route path="/invoice_generate_data" component={GenerateInvoiceData} />
                        <Route path="/manual_invoice" component={ManualInvoice} />
                        <Route path="/payment_list" component={PaymentList} />
                        <Route path="/payment_data" component={PaymentData} />
                        <Route path="/chat_board" component={ChatBoard} />
                        <Route path="/credit_note" component={CreditNote} />
                        <Route path="/vender_data" component={VenderProfileData} />
                        <Route path="/vender_list" component={VenderProfileList} />
                        <Route path="/vender_view" component={VenderProfileView} />
                        <Route path="/passenger_invoice" component={PassengersInvoice} />
                        <Route path="/credit_note_list" component={CreditNoteList} />
                        <Route path="/driver_salary" component={DriverSalary} />
                        <Route path="/salary_list" component={SalaryList} />
                        <Route path="/salary_data" component={SalaryData} />
                        <Route path="/salary_view" component={SalaryView} />
                        <Route path="/org_employee_list" component={OrgEmployeeList} />
                        <Route path="/org_employee_data" component={OrgEmployeeData} />
                        <Route path="/org_employee_view" component={OrgEmployeeView} />  
                        <Route path="/vehicle_payments" component={VehiclePayments} />
                        <Route path="/vehicle_payments_data" component={VehiclePaymentsData} /> 
                        <Route path="/vehicle_hire_list" component={VehicleHireList} />
                        <Route path="/vehicle_hire_data" component={VehicleHireData} />      
                        <Route path="/voucher_list" component={VoucherList} /> 
                        <Route path="/voucher_data" component={VoucherData} />  
                        <Route path="/voucher_view" component={VoucherView} />              
                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
