import React from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
//import AuthenticationRoutes from './AuthenticationRoutes';

// project imports
import config from './../config';
import { useSelector } from 'react-redux';
//-----------------------|| ROUTING RENDER ||-----------------------//

const Routes = () => {
    const location = useLocation();

    const account = useSelector((state) => state.account);
    var { role } = account;
    var redirect_url = "";

    const hasTripIdQueryParam = () => {
        const params = new URLSearchParams(location.search);
        return params.has('page_id');
    };
    
    if(!role) {
        role = 'Others';
    }

    if (hasTripIdQueryParam()) {
        const params = new URLSearchParams(location.search);        
        const base_url = params.get('page_id') && params.get('page_id').replace(',', '/');
        const params_names = params.get('param_name') && params.get('param_name').split(',');
        const params_values = params.get('value') && params.get('value').split(',');

        let queryParams = '';
        params_names.forEach((name, index) => {
          queryParams += `${name}=${params_values[index]}&`;
        });
        queryParams = queryParams.slice(0, -1); 
        redirect_url = `/${base_url}/?${queryParams}`;
    } else {
        redirect_url= config.defaultPath[role];
    }
    
    return (
        <Switch>
            <Redirect exact from="/" to={redirect_url} />
            <React.Fragment>
                <LoginRoutes />

                <MainRoutes />
            </React.Fragment>
        </Switch>
    );
};

export default Routes;
