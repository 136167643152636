
//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//

export const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'myapi.view_logout',
            title: 'Logout',
            type: 'item',
            url: '/logout',
            icon: 'IconLogout',
            breadcrumbs: false
        },
        
    ]
};
