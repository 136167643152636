let BACKEND_SERVER = null;
let BACKEND_WS_SERVER = null;
if (process.env.REACT_APP_BACKEND_SERVER) {
  BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER;
} else {
  //BACKEND_SERVER =  "http://127.0.0.1:8000/";
  BACKEND_SERVER =   "https://esafetrip.com/api/"; //  "http://192.168.31.147:8000/" //
  //BACKEND_WS_SERVER = "ws://127.0.0.1:8000/"; //"wss://esafetrip.com/api/"; // "ws://192.168.31.147:8000/" //  
  BACKEND_WS_SERVER = "wss://esafetrip.com/api/"; // "ws://192.168.31.147:8000/" //  
  //mysite/react-ui/src/assets/scss mysite/react-ui/src/assets/images
}

const roleBasedPath = {
  Employee: '/travel_request_list',
  ClientAdmin: '/travel_request_list',
  Driver: '/trip/triplist/',
  Admin: '/dashboard/default',
  Others: '/dashboard/default',
}

const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default's
    basename: '',
    defaultPath: roleBasedPath,
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 3,
    API_SERVER: BACKEND_SERVER,
    WS_SERVER: BACKEND_WS_SERVER,
    TITLE_TXT: 'eSafe Trip',
    clientURL: 'https://esafetrip.com/d/', //'http://localhost:5000/d/'
    EmployeeAuthirization: true,
    ADMIN_MAILID: 'admin@esafetrip.com',
    ST_No: 'AHOPA7992CST001',
    LUT_AR: 'AD290324039103D',
    SAC_NO: '996601',
    GST_NO: '29AHOPA7992C1Z0'
};

export default config;
