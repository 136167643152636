import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import axios from 'axios';

// project imports
import config from '../../config';
//-----------------------|| GUEST GUARD ||-----------------------//

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */
const GuestGuard = ({ children }) => {
    const account = useSelector((state) => state.account);
    const { isLoggedIn, user, token, role } = account;
    const location = useLocation();

    console.log('session info :',user, token)
    
    if (location.pathname != '/otp_input/' && location.pathname != '/driver_trip/' ){
        
        if (isLoggedIn) {
        return <Redirect to={config.defaultPath[role]} />;
        }
        console.log('inside the login')
    }

    return children;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
