
//-----------------------|| securities MENU ITEMS ||-----------------------//
export const masters = {
    id: 'masters',
    title: 'Masters',
    type: 'group',
    children: [
        {
            id: 'myapi.change_tbldriver',
            title: 'Driver',
            type: 'item',
            url: '/driver_list',
            icon: 'IconSteeringWheel',
            breadcrumbs: false
        },
        {
            id: 'myapi.view_tblvehicle',
            title: 'Vehicle',
            type: 'item',
            url: '/vehicle_list',
            icon: 'IconCar',
            breadcrumbs: false
        },
        {
            id: 'myapi.view_tblclient',
            title: 'Clients',
            type: 'item',
            url: '/client_list',
            icon: 'IconBriefcase',
            breadcrumbs: false
        },
        {
            id: 'myapi.view_tblclientproject',
            title: 'Client Projects',
            type: 'item',
            url: '/client_project_list',
            icon: 'IconFileText',
            breadcrumbs: false
        },
        {
            id: 'myapi.view_tblclientcommercial',
            title: 'Client Commercials',
            type: 'item',
            url: '/client_commercial_list',
            icon: 'IconListCheck',
            breadcrumbs: false
        },
        {
            id: 'myapi.view_employee',
            title: 'Employee',
            type: 'item',
            url: '/employee_list',
            icon: 'IconUser',
            breadcrumbs: false
        },
        {
            id: 'auth.view_user',
            title: 'User Management',
            type: 'item',
            url: '/user_management_list',
            icon: 'IconUsers',
            breadcrumbs: false
        },
        {
            id: 'myapi.change_profile_settings',
            title: 'Settings',
            type: 'item',
            url: '/employee_profile_setting',
            icon: 'IconSettings',
            breadcrumbs: false
        },
        {
            id: 'myapi.view_tblbankaccounts',
            title: 'Bank Accounts',
            type: 'item',
            url: '/bank_acc_list',
            icon: 'IconBuildingBank',
            breadcrumbs: false
        },
        {
            id: 'accounts.view_vendorgstprofile',
            title: 'Accounts/Profile',
            type: 'item',
            url: '/vender_list',
            icon: 'IconUserPlus',
            breadcrumbs: false
        },
    ]
};
