import Paper from '@mui/material/Paper';
import colors from '../assets/scss/_themes-vars.module.scss';
import { darken, lighten, styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { Button, CircularProgress, Backdrop, Grid } from '@mui/material';
import { Redirect } from 'react-router-dom';
import React  from 'react';
import axios from 'axios';


export const getDateTime = ((strdate) => {
  let dtTime =null
  if (strdate !== null){
    dtTime = new Date(strdate)
  }
  return dtTime;
});

export const toDateTime = (strdate) => {
  let formattedDate = null;
  if (strdate !== null) {
    const ydate = new Date(strdate);

    const day = ydate.getDate().toString().padStart(2, '0');
    const month = (ydate.getMonth() + 1).toString().padStart(2, '0');
    const year = ydate.getFullYear();
    const hours = ydate.getHours().toString().padStart(2, '0');
    const minutes = ydate.getMinutes().toString().padStart(2, '0');

    formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
  }
  return formattedDate;
};

export const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  export const TripDatagridSx = {
    "& .MuiDataGrid-columnHeaderTitle": {
      whiteSpace: "normal",
      lineHeight: "normal",
    },
    "& .MuiDataGrid-columnHeader": {
      height: "unset !important"
    },
    "& .MuiDataGrid-columnHeaders": {
      maxHeight: "168px !important"
    },
      "& .MuiDataGrid-columnHeaders": {
      backgroundColor: colors.secondaryDark, 
      color: colors.secondaryDark, 
      fontSize: 16
    }
  }

  export const datagridSx = {
    borderRadius: 2,
    "& .MuiDataGrid-main": { borderRadius: 2 },
    "& .MuiDataGrid-columnHeaderTitle": {
      whiteSpace: "normal",
      lineHeight: "normal"
    },
    "& .MuiDataGrid-columnHeader": {
      height: "unset !important"
    },
    "& .MuiDataGrid-columnHeaders": {
      maxHeight: "168px !important"
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .MuiDataGrid-row": {
        "&:nth-of-type(2n)": { backgroundColor: colors.secondaryLight }
      }
    }, 
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: colors.secondaryDark, 
      // color: colors.secondaryDark,
      fontSize: 16
    }
  
  };
  
  export function filterBy(keyName, keyValue,  datalist){
    var flist = datalist.filter ( (item)=>{
      return (item[keyName] === keyValue)
    })
    var obj=null
    if (flist) {obj=flist[0]}

    return obj
  };

export const toDBDateTime = ((strdate) => {
    let dtTime =''
    if (strdate !== null){
      let ydate = new Date(strdate)
      console.log(ydate)
      console.log("iso", ydate.toISOString())
      dtTime = ydate.toISOString()
    }
    return dtTime;
});

export const toDBDate = ((strdate) => {
  console.log(strdate)
  var db_date = ''
  if (strdate !== null){
      var tdate = new Date(strdate);
        db_date = tdate.getFullYear()+'-'+(tdate.getMonth()+1)+'-'+tdate.getDate();
      console.log(db_date)
  }
  return db_date
});

export const getDateFormat = ((strdate) => {
  var db_date = ''
  if (strdate !== null){
      var tdate = new Date(strdate);
      const day = ('0'+ tdate.getDate()).slice(-2);
      const month = ('0'+ (tdate.getMonth() +1)).slice(-2);
      db_date = day +'/'+ month +'/'+ tdate.getFullYear();
  }
  return db_date
});

export const AutocompleteX = {
  display: 'inline-block',  width: '100%' , bgcolor: 'background.paper',  
};

export const Mandatory = () =>{ return (<span className='mandatoryStar'>*</span> ) }


const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .super-app-theme--Booked': {
    backgroundColor: getBackgroundColor(
      colors.orangeMain,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        colors.orangeMain,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        colors.orangeMain,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          colors.orangeMain,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .super-app-theme--BookedAlert': {
    backgroundColor: theme.palette.error.main ,
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .super-app-theme--Assigned': {
    backgroundColor: getBackgroundColor(
      colors.successDark,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        colors.successDark,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        colors.successDark,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          colors.successDark,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .super-app-theme--Submitted': {
    backgroundColor: getBackgroundColor(
      colors.primaryDark,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        colors.primaryDark,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        colors.primaryDark,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          colors.primaryDark,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .super-app-theme--Completed': {
    backgroundColor: getBackgroundColor(
      colors.secondaryDark,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        colors.secondaryDark,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        colors.secondaryDark,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          colors.secondaryDark,
          theme.palette.mode,
        ),
      },
    },
  },
}));


export const SubmitSuccess = (redirect, pathname, ButtonName) => {
  return (
    (redirect ?
      (<Redirect to={pathname} />) : 
      (<Grid item>
          <Button variant="contained" disabled> {ButtonName} </Button>
          <Backdrop open={true}>
              <CircularProgress color="inherit" />
          </Backdrop>
      </Grid>))
  )
}

export const waitingTime =  () => {
  return 2000;
}

export const toYearFormat = ((strdate) => {
  var db_date = ''
  if (strdate !== null){
      var tdate = new Date(strdate);
        db_date = tdate.getFullYear()+'-'+(tdate.getMonth()+1)+'-'+tdate.getDate();
  }
  return db_date
});

export function errorHandling(response, setError) {
  if(response){
    if(response.status == 401) {
      setError(<span style={{ color: 'red'}}>
        You are not Authorized, Please Check Session or Login Again
      </span>);
    } else if(response.status == 404) {
      setError(<span style={{ color: 'red'}}>
        The Requested Content Not Found
      </span>);
    } else if(response.status == 500) {
      setError(<span style={{ color: 'red'}}>
        Internal Server Error, Please Contact Admin
      </span>);
    } else if(response.status == 400) {
      setError(<span style={{ color: 'red'}}>
        Please Provide Valid Information
      </span>);
    }
  }
  if(!navigator.onLine){
    setError(<span style={{ color: 'red'}}>
      No Internet Connection, Please check your network connection and try again
    </span>);
  }
} ;
export  function initMapWithZoomButtons(map) {
  var zoomInButton = document.createElement('button');
  zoomInButton.innerHTML = '+';
  zoomInButton.className = 'zoomButtonin';
  zoomInButton.onclick = function() {
      var currentZoom = map.getZoom();
      map.setZoom(currentZoom + 1);
  };
  map.getContainer().appendChild(zoomInButton);

  var zoomOutButton = document.createElement('button');
  zoomOutButton.innerHTML = '-';
  zoomOutButton.className = 'zoomButton';
  zoomOutButton.onclick = function() {
      var currentZoom = map.getZoom();
      map.setZoom(currentZoom - 1);
  };
  map.getContainer().appendChild(zoomOutButton);
}
